import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Button, Header, Icon, Grid, Image, Message, Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import parse from '../../lib/parse';

import Form from '../../components/Form';
import PhoneInput from '../../components/PhoneInput';
import Input from '../../components/Input';
import Modal from '../../components/Modal';

const styles = {
  savedChannelIconStyle: {
    color: '#2c662d',
  },
  channelStyle: {
    color: '#333333',
    background: 'white',
  },
  selectedChannelStyle: {
    boxShadow: '8px 0px 0px #999999 inset',
    border: 'solid 1px #999999',
    background: 'white',
  },
};

const minScreenWidth = 450;

@inject('store')
@graphql(gql`query OnboardingChannelsQuery($id: ID!) {
  node(id: $id) @connection(key: "Onboarding", filter: ["id"]) {
    ... on Onboarding {
      id: dbId
      step
      channelJson
      organization {
        isOnboarding
      }
    }
  }
}`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.onboarding_id
    }
  })
})
@graphql(gql`mutation updateOnboarding($updateOnboardingMutation: UpdateOnboardingInput!) {
  updateOnboarding(input: $updateOnboardingMutation) {
    clientMutationId
  }
}`, {
  name: 'updateOnboarding',
  options: {
    refetchQueries: ['OnboardingInvitesQuery']
  }
})
@observer
export default class OnboardingChannels extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Channels');
  }

  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      error: {
        channelName: undefined,
        people: [{
          name: undefined,
          phone: undefined,
          email: undefined,
        }],
      },
      channels: [],
      selectedChannel: {
        index: -1,
        name: '',
        people: [{
          name: '',
          phone: {
            phoneNumber: '',
            countryCode: '+55'
          },
          email: '',
        }],
      },
      unsavedChannel: {
        name: '',
        people: [{
          name: '',
          phone: {
            phoneNumber: '',
            countryCode: '+55'
          },
          email: '',
        }],
      },
      showForm: false,
    };

    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.step) {
      const { onboarding_id } = this.props.params;
      const { step, channelJson, organization } = nextProps.data.node;

      if (!organization.isOnboarding) {
        this.props.router.push('/');
      } else if (step === 'GROUPS') {
        this.props.router.push(`/start/${onboarding_id}/groups`);
      } else {
        this.setState({ step, channels: channelJson ? JSON.parse(channelJson) : [] });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ screenWidth: window.innerWidth });
  }

  addPersonFields = () => {
    const { selectedChannel, error } = this.state;
    selectedChannel.people.push({
      name: '',
      phone: {
        phoneNumber: '',
        countryCode: '+55'
      },
      email: '',
    });

    error.people.push({
      name: undefined,
      phone: undefined,
      email: undefined,
    });

    this.setState({ error, selectedChannel });
  }

  changePersonField = (i, name, value) => {
    this.state.selectedChannel.people[i][name] = value;
    this.forceUpdate();
  }

  changePhone = (i, name, field, value) => {
    this.state.selectedChannel.people[i][name][field] = value;
    this.forceUpdate();
  }

  removePersonFields = (i) => {
    this.state.selectedChannel.people.splice(i, 1);
    this.forceUpdate();
  }

  saveChannel = (e) => {
    e.preventDefault();

    const { selectedChannel } = this.state;
    const { onboarding_id } = this.props.params;
    const { updateOnboarding } = this.props;

    if (this.validateChannelData(selectedChannel)) {
      let channels;
      if (selectedChannel.index === -1) {
        channels = _.concat(this.state.channels, _.omit(selectedChannel, ['index', 'error']));
      } else {
        channels = _.slice(this.state.channels);
        channels[selectedChannel.index] = _.omit(selectedChannel, ['index', 'error']);
      }

      const request = { mutate: updateOnboarding, input: 'updateOnboardingMutation', message: __('The channel %s was saved', selectedChannel.name) };
      request.mutate({
        variables: {
          [request.input]: {
            id: onboarding_id,
            channelJson: JSON.stringify(channels)
          }
        }
      }).then(() => {
        this.state.channels = channels;
        if (selectedChannel.index === -1) {
          this.state.unsavedChannel = {
            name: '',
            people: [{
              name: '',
              phone: {
                phoneNumber: '',
                countryCode: '+55'
              },
              email: '',
            }],
          };
        }
        this.state.selectedChannel = Object.assign({ index: -1 }, this.state.unsavedChannel);
        this.props.store.snackbar = { active: true, message: request.message, success: true };
        this.forceUpdate();
      }).catch((err) => {
        this.props.store.appends.push(
          <Modal
            key={'modal-error-save-channel'}
            onClose={() => this.props.store.appends.pop()}
            header={__('Error')}
            content={__('Error saving this channel. Try again.')}
            actions={[
              <Button
                content={<span><Icon name="times" />{__('Close')}</span>}
                onClick={() => {
                  this.props.store.appends.pop();
                }}
              />
            ]}
          />
        );
        console.error(err);
      });
    }
  }

  deleteChannel = (e) => {
    e.preventDefault();

    this.props.store.appends.push(
      <Modal
        key={'modal-delete'}
        onClose={() => this.props.store.appends.pop()}
        header={__('Are you sure you want to delete this channel?')}
        content={__('This action can not be undone.')}
        actions={[
          <Button
            positive
            icon="check"
            labelPosition="left"
            content={__('Yes')}
            onClick={() => {
              const { onboarding_id } = this.props.params;
              const { updateOnboarding } = this.props;
              const request = { mutate: updateOnboarding, input: 'updateOnboardingMutation', message: __('The channel %s was removed', this.state.selectedChannel.name) };
              const removeIndex = this.state.selectedChannel.index;
              const channels = _.slice(this.state.channels);
              channels.splice(removeIndex, 1);

              request.mutate({
                variables: {
                  [request.input]: {
                    id: onboarding_id,
                    channelJson: JSON.stringify(channels)
                  }
                }
              }).then(() => {
                this.state.channels = channels;
                this.changeSelectedChannel(-1);
                this.props.store.appends.pop();
                this.props.store.snackbar = { active: true, message: request.message, success: true };
                this.forceUpdate();
              }).catch((err) => {
                this.props.store.appends.pop();
                this.props.store.appends.push(
                  <Modal
                    key={'modal-error-delete-channel'}
                    onClose={() => this.props.store.appends.pop()}
                    header={__('Error')}
                    content={__('Error deleting this channel. Try again.')}
                    actions={[
                      <Button
                        content={<span><Icon name="times" />{__('Close')}</span>}
                        onClick={() => {
                          this.props.store.appends.pop();
                        }}
                      />
                    ]}
                  />
                );
                console.error(err);
              });
            }}
          />,
          <Button
            negative
            icon="times"
            labelPosition="left"
            content={__('No')}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
      />
    );
  }

  validateChannelData = (channel) => {
    const error = {};

    error.channelName = this.validateNameField('channel', channel.name);

    error.people = channel.people.map((s) => {
      const e = {};

      e.name = this.validateNameField('person', s.name);
      e.email = this.validateEmailField(s.email);
      e.phone = this.validatePhoneField(s.phone);

      e.contact = s.phone.phoneNumber.trim() === '' && s.email.trim() === '' ? __('You must insert at least one of these: phone number or email') : undefined;

      return e;
    });

    this.setState({ error });

    return !error.channelName && !_.flattenDeep(error.people.map(s => Object.values(s))).some(v => !!v);
  }

  validatePhoneField = phone => (phone.phoneNumber.match(/\D/g) !== null || parse.validatePhone(phone.countryCode, phone.phoneNumber.trim(), true) === false ? __('Invalid phone') : undefined)

  validateEmailField = email => (parse.validateEmail(email.trim()) === false ? __('Invalid e-mail') : undefined)

  validateNameField = (type, value) => {
    if (type === 'person') {
      return value.trim() === '' ? __('The person name can not be empty') : undefined;
    } else if (type === 'channel') {
      return value.trim() === '' ? __('The channel name can not be empty') : undefined;
    }
    return undefined;
  }

  selectChannel = (i) => {
    if (this.state.selectedChannel.index !== -1 && !_.isEqual(this.state.channels[this.state.selectedChannel.index], _.omit(this.state.selectedChannel, ['index']))) {
      this.props.store.appends.push(
        <Modal
          key={'modal-change'}
          onClose={() => this.props.store.appends.pop()}
          header={__('You have unsaved changes, do you want to continue?')}
          content={__('All your changes will be lost.')}
          actions={[
            <Button positive icon="check" labelPosition="left" content={__('Yes')} onClick={() => { this.changeSelectedChannel(i); this.props.store.appends.pop(); }} />,
            <Button negative icon="times" labelPosition="left" content={__('No')} onClick={() => this.props.store.appends.pop()} />
          ]}
        />
      );
    } else {
      this.changeSelectedChannel(i);
    }
  }

  changeSelectedChannel = (i) => {
    let channel = { index: i };

    if (i === -1) {
      channel = Object.assign(channel, this.state.unsavedChannel);
    } else {
      const people = _.cloneDeep(this.state.channels[i].people);
      channel = Object.assign(channel, this.state.channels[i], { people });
    }

    const error = {
      channelName: undefined,
      people: Array(channel.people.length).fill({
        name: undefined,
        phone: undefined,
        email: undefined,
      }),
    };

    this.setState({
      error,
      selectedChannel: channel,
      unsavedChannel: this.state.selectedChannel.index === -1 ? _.omit(this.state.selectedChannel, ['index']) : this.state.unsavedChannel,
    });
  }

  nextStep = () => {
    const { onboarding_id } = this.props.params;
    const { updateOnboarding } = this.props;
    const request = { mutate: updateOnboarding, input: 'updateOnboardingMutation' };

    request.mutate({
      variables: {
        [request.input]: {
          id: onboarding_id,
          step: 'INVITES'
        }
      }
    }).then(async () => {
      await this.props.data.updateQuery((previousResult) => {
        const nextResult = _.cloneDeep(previousResult);

        nextResult.node.step = 'INVITES';

        return nextResult;
      });

      this.props.router.push(`/start/${onboarding_id}/invites`);
    }).catch((err) => {
      this.props.store.appends.push(
        <Modal
          key={'modal-error-next-step'}
          onClose={() => this.props.store.appends.pop()}
          header={__('Error')}
          content={__('An error occurred. Try again.')}
          actions={[
            <Button
              content={<span><Icon name="times" />{__('Close')}</span>}
              onClick={() => {
                this.props.store.appends.pop();
              }}
            />
          ]}
        />
      );
      console.error(err);
    });
  }

  renderChannelForm = () => {
    const { selectedChannel, unsavedChannel, channels, screenWidth } = this.state;

    if (screenWidth >= minScreenWidth) {
      const { error } = this.state;

      const wasEdited = this.state.selectedChannel.index !== -1 && !_.isEqual(this.state.channels[this.state.selectedChannel.index], _.omit(this.state.selectedChannel, ['index']));

      const phoneKey = selectedChannel.index === -1 ? channels.length : selectedChannel.index;

      const people = selectedChannel.people.map((person, i) => (
        <div style={{ paddingBottom: '16px', paddingTop: '0px', display: 'inline-flex' }} key={`person-${i}`}>
          <span style={{ width: '230px' }}>
            <Form.Field
              autoFocus={selectedChannel.name !== '' || !!error.channelName}
              label={i === 0 ? __('Person\'s Name') : ''}
              placeholder={__('Person\'s Name')}
              value={person.name}
              onChange={(e, { value }) => this.changePersonField(i, 'name', value)}
              control={Input}
              icon={false}
              error={!!error && !!error.people[i] && !!error.people[i].name ? error.people[i].name : false}
              onBlur={() => {
                error.people[i].name = this.validateNameField('person', selectedChannel.people[i].name);
                this.setState({ error });
              }}
            />
          </span>
          <span style={{ padding: '0 0 0 0.5em', width: 'auto' }}>
            <Form.Field
              control={PhoneInput}
              label={i === 0 ? __('Person\'s Phone') : ''}
              placeholder={__('Person\'s Phone')}
              country={person.phone.countryCode}
              phone={person.phone.phoneNumber}
              key={`channel-${phoneKey}-${i}`}
              onCountryChange={countryCode => this.changePhone(i, 'phone', 'countryCode', countryCode || '')}
              onPhoneChange={phoneNumber => this.changePhone(i, 'phone', 'phoneNumber', phoneNumber || '')}
              error={!!error && !!error.people[i] && (!!error.people[i].contact || !!error.people[i].phone) ? error.people[i].contact || error.people[i].phone : false}
              hasError={!!error && !!error.people[i] && (!!error.people[i].contact || !!error.people[i].phone)}
              onPhoneBlur={() => {
                error.people[i].contact = selectedChannel.people[i].phone.phoneNumber.trim() === '' ? error.people[i].contact : undefined;
                error.people[i].phone = this.validatePhoneField(selectedChannel.people[i].phone);
                this.setState({ error });
              }}
              style={{ width: '365px' }}
              flag
            />
          </span>
          <span style={{ width: '220px', padding: '0 0 0 0.5em' }}>
            <Form.Field
              label={i === 0 ? __('Person\'s E-mail') : ''}
              placeholder={__('Person\'s E-mail')}
              value={person.email}
              onChange={(e, { value }) => this.changePersonField(i, 'email', value)}
              control={Input}
              icon={false}
              onBlur={() => {
                error.people[i].contact = selectedChannel.people[i].email.trim() === '' ? error.people[i].email : undefined;
                error.people[i].email = this.validateEmailField(selectedChannel.people[i].email);
                this.setState({ error });
              }}
              error={!!error && !!error.people[i] && (!!error.people[i].contact || !!error.people[i].email) ? error.people[i].contact || error.people[i].email : false}
            />
          </span>

          <span style={{ padding: '0 0.5em', marginTop: 'auto' }}>
            <Button
              type="button" data-action="remove-channel"
              icon="times" basic onClick={() => this.removePersonFields(i)}
            />
          </span>
        </div>
      ));

      return (
        <Container textAlign="left" style={{ marginTop: '45px', padding: '0 5%' }} fluid>
          <Grid divided="vertically" stackable columns="equal">
            <Grid.Row style={{ height: '64vh' }} >
              <Grid.Column width={4} style={{ overflow: 'auto', padding: '0.5em', margin: '0.5em' }}>
                {this.renderChannelsList()}
              </Grid.Column>
              <Grid.Column style={{ overflow: 'auto', margin: '0.5em' }}>
                <Form>
                  <div>
                    <style>{'#addPerson, #deleteChannel { box-shadow: none !important; }'}</style>
                    <div style={{ margin: '16px 0 24px 0' }}>
                      <label style={{ fontSize: '1.1em', fontWeight: 'bolder' }}>{__('Channel name')}</label>
                      <Form.Input
                        autoFocus={selectedChannel.name === ''}
                        value={selectedChannel.name}
                        placeholder={__('Channel name')}
                        style={{ marginTop: '5px', width: '400px' }}
                        onChange={(e, { value }) => this.setState({ selectedChannel: { ...selectedChannel, name: value } })}
                        control={Input}
                        icon={false}
                        error={!!error && !!error.channelName ? error.channelName : false}
                        onBlur={(e) => {
                          if (!e.relatedTarget || !e.currentTarget || e.relatedTarget.parentNode !== e.currentTarget.parentNode.parentNode.parentNode) {
                            error.channelName = this.validateNameField('channel', selectedChannel.name);
                            this.setState({ error });
                          }
                        }}
                      />
                      {`${__('Suggestions')}: `}
                      <Button
                        basic
                        type="button"
                        onClick={() => {
                          error.channelName = undefined;
                          this.setState({
                            selectedChannel: {
                              ...selectedChannel,
                              name: __('Coordination'),
                              error: { error }
                            }
                          });
                        }}
                        content={__('Coordination')}
                        style={{ padding: '0.5em' }}
                      />
                      <Button
                        basic
                        type="button"
                        onClick={() => {
                          error.channelName = undefined;
                          this.setState({
                            selectedChannel: {
                              ...selectedChannel,
                              name: __('Direction'),
                              error: { error }
                            }
                          });
                        }}
                        content={__('Direction')}
                        style={{ padding: '0.5em' }}
                      />
                      <Button
                        basic
                        type="button"
                        onClick={() => {
                          error.channelName = undefined;
                          this.setState({
                            selectedChannel: {
                              ...selectedChannel,
                              name: __('Financial'),
                              error: { error }
                            }
                          });
                        }}
                        content={__('Financial')}
                        style={{ padding: '0.5em' }}
                      />
                      <Button
                        basic
                        type="button"
                        onClick={() => {
                          error.channelName = undefined;
                          this.setState({
                            selectedChannel: {
                              ...selectedChannel,
                              name: __('Secretary'),
                              error: { error }
                            }
                          });
                        }}
                        content={__('Secretary')}
                        style={{ padding: '0.5em' }}
                      />
                    </div>

                    {people}

                    <div style={{ marginTop: '14px' }}>
                      <Button basic color="blue" onClick={this.addPersonFields} id="addPerson" >
                        <Icon name="plus" /> {__('Add another person')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ display: 'block' }}>
              <Button type="button" style={{ padding: '16px 24px', float: 'left' }} onClick={this.confirmContinueLater}>
                {__('Continue later')}
              </Button>
              <div style={{ float: 'right' }}>
                <Button
                  basic
                  negative
                  size="tiny"
                  id="deleteChannel"
                  onClick={this.deleteChannel}
                  style={{
                    padding: '16px 24px',
                    display: channels.length > 0 || unsavedChannel !== undefined ? 'inline-block' : 'none',
                  }}
                >
                  <Icon name="trash alt" /> {__('Delete this channel')}
                </Button>
                <Button
                  basic
                  negative
                  type="button"
                  style={{
                    padding: '16px 24px',
                    display: channels.length > 0 && selectedChannel.index !== -1 && wasEdited ? 'inline-block' : 'none',
                  }}
                  onClick={this.saveChannel}
                  disabled={selectedChannel.people.length === 0 || !wasEdited}
                >
                  <Icon name="times" /> {__('Discard changes')}
                </Button>
                <Button
                  positive
                  type="button"
                  style={{
                    padding: '16px 24px',
                    display: (channels.length > 0 && wasEdited) || unsavedChannel !== undefined ? 'inline-block' : 'none',
                  }}
                  onClick={this.saveChannel}
                >
                  <Icon name="save" /> {__('Save channel')}
                </Button>
                <Button
                  primary
                  type="button"
                  style={{
                    padding: '16px 24px',
                    display: channels.length > 0 && unsavedChannel === undefined && !wasEdited ? 'inline-block' : 'none'
                  }}
                  disabled={channels.length === 0 || unsavedChannel !== undefined || wasEdited}
                  onClick={this.nextStep}
                >
                  {__('Next step')} <Icon name="arrow right" />
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
    return (
      <Message negative>
        <Message.Header>{__('Display too small')}</Message.Header>
        <p>{__('Your display width is smaller than the minimum requested to use this feature. The minimum width is %s pixels.', minScreenWidth)}</p>
        <p>{__('If you are using a mobile device, like a smartphone or tablet, try using it on landscape orientation.')}</p>
      </Message>
    );
  }

  renderChannelsList = () => {
    const { selectedChannel, channels, unsavedChannel } = this.state;

    const channelItems = channels.map((g, i) => {
      const edited = selectedChannel.index === i && !_.isEqual(channels[i], _.omit(selectedChannel, ['index']));
      const size = selectedChannel.index === i ? selectedChannel.people.length : g.people.length;

      return (
        <div className="pointer" onClick={() => this.selectChannel(i)} style={{ marginBottom: '0.5em' }} key={`${g.name}-${i}`}>
          <Message
            icon size="small"
            style={selectedChannel.index === i ? styles.selectedChannelStyle : styles.channelStyle}
          >
            <Icon name={edited ? 'pencil alt' : 'check circle'} style={edited ? styles.channelStyle : styles.savedChannelIconStyle} />
            <Message.Content style={styles.channelStyle}>
              <Message.Header className="wordWrapped">{selectedChannel.index === i ? selectedChannel.name : g.name}</Message.Header>
              <p>
                <Icon name="users" />
                {`${size} ${size === 1 ? __('person') : __('people')}`}
              </p>
            </Message.Content>
          </Message>
        </div>
      );
    });

    const size = selectedChannel.index === -1 ? selectedChannel.people.length : unsavedChannel.people.length;

    return (
      <Container textAlign="left">
        <Header as="h3">{__('Service channels')}</Header>
        <p>{__('Sectors of the school that are responsible communication with employees and families.')}</p>

        {channelItems}

        <div className="pointer" onClick={() => this.selectChannel(-1)} >
          <Message icon size="small" style={selectedChannel.index === -1 ? styles.selectedChannelStyle : styles.channelStyle}>
            <Icon name={selectedChannel.index === -1 ? 'pencil alt' : 'pencil'} style={styles.channelStyle} />
            <Message.Content style={styles.channelStyle} >
              <Message.Header className="wordWrapped">{
                selectedChannel.index === -1 ?
                  (selectedChannel.name === '' ? __('Unnamed Channel') : selectedChannel.name) :
                  (unsavedChannel.name === '' ? __('Unnamed Channel') : unsavedChannel.name)
              }</Message.Header>
              <p>
                <Icon name="users" />
                {`${size} ${size === 1 ? __('person') : __('people')}`}
              </p>
            </Message.Content>
          </Message>
        </div>
      </Container>
    );
  }

  confirmContinueLater = () => {
    this.props.store.appends.push(
      <Modal
        key={'modal-leave'}
        onClose={() => this.props.store.appends.pop()}
        header={__('Do you really want to complete the onboarding later?')}
        content={__('The onboarding is very important to start using ClassApp. You can insert these informations at any time.')}
        actions={[
          <Button content={<span><Icon name="arrow left" />{__('Back')}</span>} onClick={() => this.props.store.appends.pop()} floated="left" />,
          <Button primary content={__('Continue later')} onClick={() => { this.props.router.push('/'); this.props.store.appends.pop(); }} />,
        ]}
      />
    );
  }

  renderStartScreen = () => {
    const { screenWidth } = this.state;

    return (
      <Container>
        <Image src={utils.asset('/onboarding/Crie-canais-de-atendimento_2.png')} size="large" centered style={{ marginBottom: '40px', marginTop: '42px' }} />
        <Header as="h2" style={{ marginBottom: '24px', marginTop: '0' }}>
          {__('Create your service channels')}
        </Header>
        <p style={{ marginBottom: 0 }}>
          {__('For effective communication, sign some service channels so that the school can communicate with parents and people.')}
        </p>
        {screenWidth >= minScreenWidth ?
          <Button
            primary
            type="button"
            style={{ padding: '16px 24px', marginTop: '18px' }}
            onClick={() => this.setState({ showForm: true })}
          >
            {__('Create your first service channel')} <Icon name="arrow right" />
          </Button>

          :

          <Message negative>
            <Message.Header>{__('Display too small')}</Message.Header>
            <p>{__('Your display width is smaller than the minimum requested to use this feature. The minimum width is %s pixels.', minScreenWidth)}</p>
            <p>{__('If you are using a mobile device, like a smartphone or tablet, try using it on landscape orientation.')}</p>
          </Message>
        }
      </Container>
    );
  }

  render() {
    const { showForm, step } = this.state;

    if (step) {
      return (
        <Container id="OnboardingChannels" style={{ paddingTop: '8px', maxHeight: '50%' }} textAlign="center" fluid>
          <Loader active={!step} />
          {showForm ? this.renderChannelForm() : this.renderStartScreen()}
        </Container>
      );
    }
    return (
      <Container id="OnboardingChannels" style={{ paddingTop: '8px', maxHeight: '50%' }} textAlign="center" fluid>
        <Loader active />
      </Container>
    );
  }
}

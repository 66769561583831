import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Button, Header, Icon, Image } from 'semantic-ui-react';


import { __ } from '../../i18n';
import * as utils from '../../utils';

import Steps from '../../components/Steps';

@inject('store') @observer
export default class OnboardingTips extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Tips');
  }

  constructor(props) {
    super(props);

    this.state = {
      tip: 'divulgation',
    };
  }

  renderDivulgationTip = steps => (
    <Container>
      <Image src={utils.asset('/onboarding/Divulgacao-dica1.png')} size="medium" centered style={{ marginBottom: '40px', marginTop: '42px' }} />
      <Header as="h2" style={{ marginBottom: '24px', marginTop: '0' }}>
        {__('Divulgation')}
      </Header>
      <div style={{ minHeight: '140px' }}>
        <p style={{ margin: '0 auto', maxWidth: '550px', textAlign: 'center' }}>
          {__('To ensure the best possible ClassApp membership in your school, we encourage you to send emails and announcements to parents and employees and encourage them to use ClassApp.')}
        </p>
        <p style={{ margin: '0 auto', maxWidth: '550px', textAlign: 'center' }}>
          <style>{'#downloadSamples { box-shadow: none !important; }'}</style>
          <Button
            primary
            basic
            type="button"
            style={{ padding: '16px 24px', margin: '1em 0' }}
            id="downloadSamples"
            href={utils.asset('/onboarding/divulgation.zip')}
          >
            {__('Download here some samples of disclosure')}
          </Button>
        </p>
        <p style={{ margin: '0 auto', maxWidth: '550px', textAlign: 'center' }}>
          {__('Remember that any change needs encouragement.')}
        </p>
      </div>
      <div style={{ height: '80px', padding: '1em 0', marginTop: '1em', display: 'inline-flex' }}>
        {steps}
        <Button
          primary
          basic
          type="button"
          onClick={() => this.setState({ tip: 'support' })}
          style={{ margin: 'auto 0 auto 2em', width: '190px', padding: '16px 24px' }}
        >
          {__('Next tip')} <Icon name="arrow right" />
        </Button>
      </div>
    </Container>
  )

  renderSupportTip = steps => (
    <Container>
      <Image src={utils.asset('/onboarding/Ajuda-e-suporte-dica2.png')} size="medium" centered style={{ marginBottom: '40px', marginTop: '42px' }} />
      <Header as="h2" style={{ marginBottom: '24px', marginTop: '0' }}>
        {__('Help and support')}
      </Header>
      <div style={{ minHeight: '140px' }}>
        <p style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '550px', textAlign: 'center' }}>
          {__('You can, at any time, ask questions through the online chat available on the site by the Help button.')}
        </p>
        <p style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '550px', textAlign: 'center' }}>
          {__('Registered parents and employees will receive invitations and instructions to use ClassApp.')}
        </p>
      </div>
      <div style={{ height: '80px', padding: '1em 0', marginTop: '1em', display: 'inline-flex' }}>
        {steps}
        <Button
          primary
          basic
          type="button"
          onClick={() => this.setState({ tip: 'replace' })}
          style={{ margin: 'auto 0 auto 2em', width: '190px', padding: '16px 24px' }}
        >
          {__('Next tip')} <Icon name="arrow right" />
        </Button>
      </div>
    </Container>
  )

  renderReplaceTip = steps => (
    <Container>
      <Image src={utils.asset('/onboarding/Largue-agenda-escolar-dica3.png')} size="medium" centered style={{ marginBottom: '40px', marginTop: '42px' }} />
      <Header as="h2" style={{ marginBottom: '24px', marginTop: '0' }}>
        {__('Replace school paper calendar, tickets and posters')}
      </Header>
      <div style={{ minHeight: '140px' }}>
        <p style={{ margin: '0 auto', maxWidth: '550px', textAlign: 'center' }}>
          {__("Adopt ClassApp as an official means of communication. It's fast, practical, environmentally friendly and efficient!")} 😀
        </p>
      </div>
      <div style={{ height: '80px', padding: '1em 0', marginTop: '1em', display: 'inline-flex' }}>
        {steps}
        <Button
          primary
          type="button"
          onClick={() => this.props.router.push('/')}
          style={{ margin: 'auto 0 auto 2em', width: '230px', padding: '16px 24px' }}
        >
          {__('Start using ClassApp')}
        </Button>
      </div>
    </Container>
  )

  render() {
    const { tip } = this.state;

    let step;

    switch (tip) {
      case 'support':
        step = 1;
        break;
      case 'replace':
        step = 2;
        break;
      default:
        step = 0;
        break;
    }

    const steps = (<Steps
      name="onboarding-tips"
      items={3}
      activeItem={step}
      color="#0080FF"
      connected={false}
      size={2}
      spacing={1}
      style={{ margin: 'auto 0' }}
      onItemClick={[
        step > 0 ? () => this.setState({ tip: 'divulgation' }) : undefined,
        step > 1 ? () => this.setState({ tip: 'support' }) : undefined,
        step > 2 ? () => this.setState({ tip: 'replace' }) : undefined,
      ]}
      itemTooltip={[__('Divulgation'), __('Help and support'), __('Replace school paper calendar, tickets and posters')]}
    />);

    let tipContent;
    switch (tip) {
      case 'support':
        tipContent = this.renderSupportTip(steps);
        break;
      case 'replace':
        tipContent = this.renderReplaceTip(steps);
        break;
      default:
        tipContent = this.renderDivulgationTip(steps);
        break;
    }

    return (
      <Container id="OnboardingTips" style={{ paddingTop: '8px', maxHeight: '50%' }} textAlign="center" fluid>
        {tipContent}
      </Container>
    );
  }
}

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';

@inject('store') @observer
export default class OnboardingNode extends React.Component {

  render() {
    return (
      <Container fluid id="OnboardingNode">
        {this.props.children}
      </Container>
    );
  }
}

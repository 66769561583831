import 'react-datasheet/lib/react-datasheet.css';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Button, Header, Icon, Grid, Image, Message } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import parse from '../../lib/parse';

import Form from '../../components/Form';
import PhoneInput from '../../components/PhoneInput';
import Input from '../../components/Input';
import Modal from '../../components/Modal';

const styles = {
  savedGroupIconStyle: {
    color: '#2c662d',
  },
  groupStyle: {
    color: '#333333',
    background: 'white',
  },
  selectedGroupStyle: {
    boxShadow: '8px 0px 0px #999999 inset',
    border: 'solid 1px #999999',
    background: 'white',
  },
};

const minScreenWidth = 450;

@inject('store')
@graphql(gql`query OnboardingGroupsQuery($id: ID!) {
  node(id: $id) @connection(key: "Onboarding", filter: ["id"]) {
    ... on Onboarding {
      id: dbId
      step
      studentJson
      organization {
        isOnboarding
      }
    }
  }
}`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.onboarding_id
    }
  })
})
@graphql(gql`mutation updateOnboarding($updateOnboardingMutation: UpdateOnboardingInput!) {
  updateOnboarding(input: $updateOnboardingMutation) {
    clientMutationId
  }
}`, {
  name: 'updateOnboarding',
  options: {
    refetchQueries: ['OnboardingInvitesQuery']
  }
})
@observer
export default class OnboardingGroups extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Classes');
  }

  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      error: {
        groupName: undefined,
        students: [{
          name: undefined,
          phone: undefined,
          email: undefined,
          extraPhone: undefined,
          extraEmail: undefined,
        }],
      },
      table: [[{ value: '', placeHolder: 'Ex: Financeiro' }, { value: '', placeHolder: 'Adicione um número de celular' }],
      [{ value: 2 }, { value: 4 }]],
      groups: [],
      selectedGroup: {
        index: -1,
      },
      unsavedGroup: undefined,
      showForm: false,
    };

    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.step) {
      const { step, studentJson, organization } = nextProps.data.node;

      if (!organization.isOnboarding) {
        this.props.router.push('/');
      } else {
        const groups = studentJson ? JSON.parse(studentJson) : [];
        const unsavedGroup = groups.length > 0 ? undefined : {
          name: '',
          students: [{
            name: '',
            showExtra: false,
            phone: {
              phoneNumber: '',
              countryCode: '+55'
            },
            email: '',
            extraPhone: {
              phoneNumber: '',
              countryCode: '+55'
            },
            extraEmail: '',
          }],
        };

        const selectedGroup = Object.assign({ index: groups.length - 1 }, unsavedGroup, groups.length > 0 ? Object.assign({}, groups[groups.length - 1], { students: cloneDeep(groups[groups.length - 1].students) }) : {});
        this.setState({
          step,
          groups,
          unsavedGroup,
          selectedGroup,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  addStudentFields = () => {
    const { selectedGroup, error } = this.state;
    selectedGroup.students.push({
      name: '',
      phone: {
        phoneNumber: '',
        countryCode: '+55'
      },
      email: '',
      extraPhone: {
        phoneNumber: '',
        countryCode: '+55'
      },
      extraEmail: '',
    });

    error.students.push({
      name: undefined,
      phone: undefined,
      email: undefined,
      extraPhone: undefined,
      extraEmail: undefined,
    });

    this.setState({ error, selectedGroup });
  }

  changeStudentField = (i, name, value) => {
    this.state.selectedGroup.students[i][name] = value;
    this.forceUpdate();
  }

  changePhone = (i, name, field, value) => {
    this.state.selectedGroup.students[i][name][field] = value;
    this.forceUpdate();
  }

  removeStudentFields = (i) => {
    this.state.selectedGroup.students.splice(i, 1);
    this.forceUpdate();
  }

  addGroup = (callback = undefined) => {
    this.setState({
      unsavedGroup: {
        name: '',
        students: [{
          name: '',
          showExtra: false,
          phone: {
            phoneNumber: '',
            countryCode: '+55'
          },
          email: '',
          extraPhone: {
            phoneNumber: '',
            countryCode: '+55'
          },
          extraEmail: '',
        }],
      },
      selectedGroup: {
        index: -1,
        name: '',
        students: [{
          name: '',
          showExtra: false,
          phone: {
            phoneNumber: '',
            countryCode: '+55'
          },
          email: '',
          extraPhone: {
            phoneNumber: '',
            countryCode: '+55'
          },
          extraEmail: '',
        }],
      },
    }, callback);
  }

  saveGroup = (e) => {
    e.preventDefault();

    const { selectedGroup } = this.state;
    const { onboarding_id } = this.props.params;
    const { updateOnboarding } = this.props;

    if (this.validateGroupData(selectedGroup)) {
      let groups;
      if (selectedGroup.index === -1) {
        groups = _.concat(this.state.groups, omit(selectedGroup, ['index']));
      } else {
        groups = _.slice(this.state.groups);
        groups[selectedGroup.index] = omit(selectedGroup, ['index']);
      }

      const request = { mutate: updateOnboarding, input: 'updateOnboardingMutation', message: __('The class %s was saved', selectedGroup.name) };

      request.mutate({
        variables: {
          [request.input]: {
            id: onboarding_id,
            studentJson: JSON.stringify(groups)
          }
        }
      }).then(() => {
        this.state.groups = groups;
        if (selectedGroup.index === -1) {
          this.state.unsavedGroup = undefined;
          this.state.selectedGroup.index = this.state.groups.length - 1;
        }
        this.forceUpdate();
        this.changeSelectedGroup(this.state.selectedGroup.index);
        this.props.store.snackbar = { active: true, message: request.message, success: true };
      }).catch((err) => {
        this.props.store.appends.push(
          <Modal
            key={'modal-error-save-group'}
            onClose={() => this.props.store.appends.pop()}
            header={__('Error')}
            content={__('Error saving this class. Try again.')}
            actions={[
              <Button
                content={<span><Icon name="times" />{__('Close')}</span>}
                onClick={() => {
                  this.props.store.appends.pop();
                }}
              />
            ]}
          />
        );
        console.error(err);
      });
    }
  }

  deleteGroup = (e) => {
    e.preventDefault();

    const { selectedGroup, groups } = this.state;

    if (selectedGroup.index === -1) {
      if (groups.length > 0) {
        this.changeSelectedGroup(groups.length - 1);
      }
      this.setState({
        unsavedGroup: undefined,
      });
    } else {
      this.props.store.appends.push(
        <Modal
          key={'modal-delete'}
          onClose={() => this.props.store.appends.pop()}
          header={__('Are you sure you want to delete this class?')}
          content={__('This action can not be undone.')}
          actions={[
            <Button
              positive
              icon="check"
              labelPosition="left"
              content={__('Yes')}
              onClick={() => {
                const { onboarding_id } = this.props.params;
                const { updateOnboarding } = this.props;
                const request = { mutate: updateOnboarding, input: 'updateOnboardingMutation', message: __('The class %s was removed', this.state.selectedGroup.name) };
                const removeIndex = this.state.selectedGroup.index;
                const allGroups = _.slice(this.state.groups);
                allGroups.splice(removeIndex, 1);

                request.mutate({
                  variables: {
                    [request.input]: {
                      id: onboarding_id,
                      studentJson: JSON.stringify(allGroups)
                    }
                  }
                }).then(() => {
                  this.state.groups = allGroups;
                  if (this.state.groups.length > 0) {
                    if (this.state.selectedGroup.index === 0) {
                      this.changeSelectedGroup(0);
                    } else {
                      this.changeSelectedGroup(this.state.selectedGroup.index - 1);
                    }
                  } else {
                    this.setState({ unsavedGroup: undefined });
                  }

                  this.props.store.appends.pop();
                  this.props.store.snackbar = { active: true, message: request.message, success: true };
                  this.forceUpdate();
                }).catch((err) => {
                  this.props.store.appends.pop();
                  this.props.store.appends.push(
                    <Modal
                      key={'modal-error-delete-group'}
                      onClose={() => this.props.store.appends.pop()}
                      header={__('Error')}
                      content={__('Error deleting this class. Try again.')}
                      actions={[
                        <Button
                          content={<span><Icon name="times" />{__('Close')}</span>}
                          onClick={() => {
                            this.props.store.appends.pop();
                          }}
                        />
                      ]}
                    />
                  );
                  console.error(err);
                });
              }}
            />,
            <Button
              negative
              icon="times"
              labelPosition="left"
              content={__('No')}
              onClick={() => this.props.store.appends.pop()}
            />
          ]}
        />
      );
    }
  }

  validateGroupData = (group) => {
    const error = {};

    error.groupName = this.validateNameField('group', group.name);

    error.students = group.students.map((s) => {
      const e = {};

      e.name = this.validateNameField('student', s.name);
      e.email = this.validateEmailField(s.email);
      e.phone = this.validatePhoneField(s.phone);
      e.extraEmail = this.validateEmailField(s.extraEmail);
      e.extraPhone = this.validatePhoneField(s.extraPhone);

      e.contact = s.phone.phoneNumber.trim() === '' && s.email.trim() === '' ? __('You must insert at least one of these: phone number or email') : undefined;

      return e;
    });

    this.setState({ error });

    return !error.groupName && !_.flattenDeep(error.students.map(s => Object.values(s))).some(v => !!v);
  }

  validatePhoneField = phone => (phone.phoneNumber.match(/\D/g) !== null || parse.validatePhone(phone.countryCode, phone.phoneNumber.trim(), true) === false ? __('Invalid phone') : undefined)

  validateEmailField = email => (parse.validateEmail(email.trim()) === false ? __('Invalid e-mail') : undefined)

  validateNameField = (type, value) => {
    if (type === 'student') {
      return value.trim() === '' ? __('The student name can not be empty') : undefined;
    } else if (type === 'group') {
      return value.trim() === '' ? __('The group name can not be empty') : undefined;
    }
    return undefined;
  }

  selectGroup = (i) => {
    if (this.state.selectedGroup.index !== -1 && !_.isEqual(this.state.groups[this.state.selectedGroup.index], omit(this.state.selectedGroup, ['index']))) {
      this.props.store.appends.push(
        <Modal
          key={'modal-change'}
          onClose={() => this.props.store.appends.pop()}
          header={__('You have unsaved changes.')}
          content={__('All your changes will be lost. Do you really want to continue?')}
          actions={[
            <Button
              negative
              floated="left"
              icon="trash alt"
              labelPosition="left"
              content={__('Discard')}
              onClick={() => {
                if (i === -1 && this.state.unsavedGroup === undefined) {
                  this.addGroup(() => this.changeSelectedGroup(-1));
                } else {
                  this.changeSelectedGroup(i);
                }
                this.props.store.appends.pop();
              }}
              style={{ marginBottom: '0.5em' }}
            />,
            <Button
              floated="right"
              icon="times"
              labelPosition="left"
              content={__('Cancel')}
              onClick={() => this.props.store.appends.pop()}
              style={{ marginBottom: '0.5em' }}
            />
          ]}
        />
      );
    } else if (i === -1 && this.state.unsavedGroup === undefined) {
      this.addGroup(() => this.changeSelectedGroup(-1));
    } else {
      this.changeSelectedGroup(i);
    }
  }

  changeSelectedGroup = (i) => {
    let group = { index: i };

    if (i === -1) {
      group = Object.assign(group, this.state.unsavedGroup);
    } else {
      const students = cloneDeep(this.state.groups[i].students);
      group = Object.assign(group, this.state.groups[i], { students });
    }

    const error = {
      groupName: undefined,
      students: Array(group.students.length).fill({
        name: undefined,
        phone: undefined,
        email: undefined,
        extraPhone: undefined,
        extraEmail: undefined,
      }),
    };

    this.setState({
      error,
      selectedGroup: group,
      unsavedGroup: this.state.selectedGroup.index === -1 ? omit(this.state.selectedGroup, ['index']) : this.state.unsavedGroup,
    });
  }

  nextStep = () => {
    const { onboarding_id } = this.props.params;
    const { updateOnboarding } = this.props;
    const request = { mutate: updateOnboarding, input: 'updateOnboardingMutation' };

    request.mutate({
      variables: {
        [request.input]: {
          id: onboarding_id,
          step: 'CHANNELS'
        }
      },
    }).then(async () => {
      await this.props.data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);

        nextResult.node.step = 'CHANNELS';

        return nextResult;
      });

      this.props.router.push(`/start/${onboarding_id}/channels`);
    }).catch((err) => {
      this.props.store.appends.push(
        <Modal
          key={'modal-error-next-step'}
          onClose={() => this.props.store.appends.pop()}
          header={__('Error')}
          content={__('An error occurred. Try again.')}
          actions={[
            <Button
              content={<span><Icon name="times" />{__('Close')}</span>}
              onClick={() => {
                this.props.store.appends.pop();
              }}
            />
          ]}
        />
      );
      console.error(err);
    });
  }

  confirmContinueLater = () => {
    this.props.store.appends.push(
      <Modal
        key={'modal-leave'}
        onClose={() => this.props.store.appends.pop()}
        header={__('Do you really want to complete the onboarding later?')}
        content={__('The onboarding is very important to start using ClassApp. You can insert these informations at any time.')}
        actions={[
          <Button content={<span><Icon name="arrow left" />{__('Back')}</span>} onClick={() => this.props.store.appends.pop()} floated="left" />,
          <Button primary content={__('Continue later')} onClick={() => { this.props.router.push('/'); this.props.store.appends.pop(); }} />,
        ]}
      />
    );
  }

  renderGroupsList = () => {
    const { selectedGroup, groups, unsavedGroup } = this.state;

    const groupItems = groups.map((g, i) => {
      const edited = selectedGroup.index === i && !_.isEqual(groups[i], omit(selectedGroup, ['index']));
      const size = selectedGroup.index === i ? selectedGroup.students.length : g.students.length;
      return (
        <div className="pointer" onClick={() => this.selectGroup(i)} style={{ marginBottom: '0.5em' }} key={`${g.name}-${i}`}>
          <Message
            icon size="small"
            style={selectedGroup.index === i ? styles.selectedGroupStyle : styles.groupStyle}
          >
            <Icon name={edited ? 'pencil alt' : 'check circle'} style={edited ? styles.groupStyle : styles.savedGroupIconStyle} />
            <Message.Content style={styles.groupStyle}>
              <Message.Header className="wordWrapped">{selectedGroup.index === i ? selectedGroup.name : g.name}</Message.Header>
              <p>
                <Icon name="users" />
                {`${size} ${size === 1 ? __('student') : __('students')}`}
              </p>
            </Message.Content>
          </Message>
        </div>
      );
    });

    const size = unsavedGroup === undefined || selectedGroup.index === -1 ? selectedGroup.students.length : unsavedGroup.students.length;

    return (
      <Container textAlign="left">
        <Header as="h3">{__('Classes')}</Header>

        {groupItems}

        {unsavedGroup ?
          <div className="pointer" onClick={() => this.selectGroup(-1)} >
            <Message icon size="small" style={selectedGroup.index === -1 ? styles.selectedGroupStyle : styles.groupStyle}>
              <Icon name={selectedGroup.index === -1 ? 'pencil alt' : 'pencil'} style={styles.groupStyle} />
              <Message.Content style={styles.groupStyle} >
                <Message.Header className="wordWrapped">{
                  selectedGroup.index === -1 ?
                    (selectedGroup.name === '' ? __('Unnamed Class') : selectedGroup.name) :
                    (unsavedGroup.name === '' ? __('Unnamed Class') : unsavedGroup.name)
                }</Message.Header>
                <p>
                  <Icon name="users" />
                  {`${size} ${size === 1 ? __('student') : __('students')}`}
                </p>
              </Message.Content>
            </Message>
          </div>
          :
          ''
        }
      </Container>
    );
  }

  renderGroupForm = () => {
    const { selectedGroup, groups, screenWidth, unsavedGroup } = this.state;

    if (screenWidth >= minScreenWidth) {
      const { error } = this.state;

      const wasEdited = !_.isEqual(this.state.groups[selectedGroup.index], omit(selectedGroup, ['index']));

      const phoneKey = selectedGroup.index === -1 ? groups.length : selectedGroup.index;

      const students = selectedGroup.students.map((student, i) => (
        <div style={{ paddingBottom: '16px', paddingTop: '0px' }} key={`student-${i}`}>
          <div style={{ display: 'inline-flex' }}>
            <div style={{ display: 'inline-block', padding: '0 0.5em 0 0' }}>
              <Form.Field
                autoFocus={selectedGroup.name !== '' || !!error.groupName}
                label={i === 0 ? __('Student\'s name') : ''}
                placeholder={__('Student\'s name')}
                value={student.name}
                onChange={(e, { value }) => this.changeStudentField(i, 'name', value)}
                control={Input}
                icon={false}
                error={!!error && !!error.students[i] && !!error.students[i].name ? error.students[i].name : false}
                onBlur={() => {
                  error.students[i].name = this.validateNameField('student', selectedGroup.students[i].name);
                  this.setState({ error });
                }}
                style={{ minWidth: '200px' }}
              />
            </div>
            <div style={{ padding: '0 5px 0 0', width: '240px' }}>
              <Form.Field
                control={PhoneInput}
                label={i === 0 ? __('Parent\'s phone') : ''}
                placeholder={__('Phone')}
                country={student.phone.countryCode}
                phone={student.phone.phoneNumber}
                key={`group-${phoneKey}-${i}`}
                onCountryChange={countryCode => this.changePhone(i, 'phone', 'countryCode', countryCode || '')}
                onPhoneChange={phoneNumber => this.changePhone(i, 'phone', 'phoneNumber', phoneNumber || '')}
                error={!!error && !!error.students[i] && (!!error.students[i].contact || !!error.students[i].phone) ? error.students[i].contact || error.students[i].phone : false}
                hasError={!!error && !!error.students[i] && (!!error.students[i].contact || !!error.students[i].phone)}
                onPhoneBlur={() => {
                  error.students[i].contact = selectedGroup.students[i].phone.phoneNumber.trim() === '' ? error.students[i].contact : undefined;
                  error.students[i].phone = this.validatePhoneField(selectedGroup.students[i].phone);
                  this.setState({ error });
                }}
                style={{ width: (!!error && !!error.students[i] && (!!error.students[i].contact || !!error.students[i].phone)) ? '105px' : '145px' }}
                flag
              />
            </div>
            <div style={{ padding: '0 5px 0 0', display: 'inline-block' }}>
              <Form.Field
                label={i === 0 ? __('Parent\'s e-mail') : ''}
                placeholder={__('E-mail')}
                value={student.email}
                onChange={(e, { value }) => this.changeStudentField(i, 'email', value)}
                control={Input}
                icon={false}
                style={{ minWidth: '200px' }}
                onBlur={() => {
                  error.students[i].contact = selectedGroup.students[i].email.trim() === '' ? error.students[i].email : undefined;
                  error.students[i].email = this.validateEmailField(selectedGroup.students[i].email);
                  this.setState({ error });
                }}
                error={!!error && !!error.students[i] && (!!error.students[i].contact || !!error.students[i].email) ? error.students[i].contact || error.students[i].email : false}
              />
            </div>
            <div style={{ padding: '0 5px', marginTop: 'auto', display: 'inline-flex' }}>
              <Button
                type="button" data-action="remove-group"
                icon="times" basic onClick={() => this.removeStudentFields(i)}
              />
            </div>
          </div>

          {student.showExtra ?
            <div style={{ display: 'inline-flex', margin: '8px 0px 0px 207px' }}>
              <div style={{ padding: '0 5px 0 0', width: '240px' }}>
                <Form.Field
                  control={PhoneInput}
                  placeholder={__('Phone')}
                  country={student.extraPhone.countryCode}
                  phone={student.extraPhone.phoneNumber}
                  onCountryChange={countryCode => this.changePhone(i, 'extraPhone', 'countryCode', countryCode || '')}
                  onPhoneChange={phoneNumber => this.changePhone(i, 'extraPhone', 'phoneNumber', phoneNumber || '')}
                  error={!!error && !!error.students[i] && !!error.students[i].extraPhone ? error.students[i].extraPhone : false}
                  hasError={!!error && !!error.students[i] && !!error.students[i].extraPhone}
                  onPhoneBlur={() => {
                    error.students[i].contact = selectedGroup.students[i].extraPhone.phoneNumber.trim() === '' ? error.students[i].contact : undefined;
                    error.students[i].extraPhone = this.validatePhoneField(selectedGroup.students[i].extraPhone);
                    this.setState({ error });
                  }}
                  flag
                  style={{ width: (!!error && !!error.students[i] && !!error.students[i].extraPhone) ? '105px' : '145px' }}
                />
              </div>
              <div style={{ padding: '0 5px 0 0', display: 'inline-block' }}>
                <Form.Field
                  placeholder={__('E-mail')}
                  value={student.extraEmail}
                  onChange={(e, { value }) => this.changeStudentField(i, 'extraEmail', value)}
                  control={Input}
                  icon={false}
                  style={{ minWidth: '200px' }}
                  error={!!error && !!error.students[i] && !!error.students[i].extraEmail ? error.students[i].extraEmail : false}
                  onBlur={() => {
                    error.students[i].contact = selectedGroup.students[i].extraEmail.trim() === '' ? error.students[i].contact : undefined;
                    error.students[i].extraEmail = this.validateEmailField(selectedGroup.students[i].extraEmail);
                    this.setState({ error });
                  }}
                />
              </div>
            </div>
            : null}
          {!student.showExtra ?
            <Button
              basic
              type="button"
              color="blue"
              onClick={() => {
                const modStudents = selectedGroup.students;
                modStudents[i].showExtra = true;

                this.setState({ selectedGroup: { ...selectedGroup, students: modStudents } });
              }}
              id="addParent"
            >
              <Icon name="plus" /> {__('Add another contact')}
            </Button>
            : null}
        </div>
      ));

      return (
        <Container textAlign="left" style={{ marginTop: '45px', padding: '0 5%' }} fluid>
          <Grid divided="vertically" stackable columns="equal">
            <Grid.Row style={{ height: '64vh' }} >
              <Grid.Column width={4} style={{ overflow: 'auto', padding: '0.5em', margin: '0.5em' }}>
                {this.renderGroupsList()}
                <div style={{ margin: '1em auto', width: '80%' }}>
                  <Button
                    basic
                    color="blue"
                    onClick={() => this.selectGroup(-1)}
                    style={{ width: '100%' }}
                    disabled={unsavedGroup !== undefined}
                  >
                    <Icon name="plus" /> {__('Add another class')}
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column style={{ overflow: 'unset', margin: '0.5em' }}>
                <Form style={{ visibility: groups.length > 0 || unsavedGroup !== undefined ? 'visible' : 'hidden' }}>
                  <div>
                    <style>{'#deleteGroup, #addParent { box-shadow: none !important; }'}</style>
                    <div style={{ margin: '16px 0 24px 0' }}>
                      <label style={{ fontSize: '1.1em', fontWeight: 'bolder' }}>{__('Class name')}</label>
                      <Form.Input
                        autoFocus={selectedGroup.name === ''}
                        value={selectedGroup.name}
                        placeholder={__('Class name')}
                        style={{ marginTop: '5px', width: '400px' }}
                        onChange={(e, { value }) => this.setState({ selectedGroup: { ...selectedGroup, name: value } })}
                        control={Input}
                        icon={false}
                        error={!!error && !!error.groupName ? error.groupName : false}
                        onBlur={() => {
                          error.groupName = this.validateNameField('group', selectedGroup.name);
                          this.setState({ error });
                        }}
                      />

                    </div>
                    <div style={{ overflow: 'auto', height: '35vh' }}>
                      {students}
                    </div>
                    <div style={{ marginTop: '14px' }}>
                      <Button basic color="blue" onClick={this.addStudentFields} >
                        <Icon name="plus" /> {__('Add another student')}
                      </Button>
                    </div>

                  </div>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ display: 'block' }}>
              <Button type="button" style={{ padding: '16px 24px', float: 'left' }} onClick={this.confirmContinueLater}>
                {__('Continue later')}
              </Button>
              <div style={{ float: 'right' }}>
                <Button
                  basic
                  negative
                  size="tiny"
                  id="deleteGroup"
                  onClick={this.deleteGroup}
                  style={{
                    padding: '16px 24px',
                    display: groups.length > 0 || unsavedGroup !== undefined ? 'inline-block' : 'none',
                  }}
                >
                  <Icon name="trash alt" /> {__('Delete this class')}
                </Button>

                <Button
                  negative
                  basic
                  type="button"
                  style={{
                    padding: '16px 24px',
                    margin: '0 0.5em',
                    display: groups.length > 0 && selectedGroup.index !== -1 && wasEdited ? 'inline-block' : 'none',
                  }}
                  onClick={() => this.selectGroup(selectedGroup.index)}
                  disabled={selectedGroup.students.length === 0 || !wasEdited}
                >
                  <Icon name="times" /> {__('Discard changes')}
                </Button>
                <Button
                  positive
                  type="button"
                  style={{
                    padding: '16px 24px',
                    display: (groups.length > 0 && wasEdited) || unsavedGroup !== undefined ? 'inline-block' : 'none',
                  }}
                  onClick={this.saveGroup}
                  disabled={selectedGroup.students.length === 0 || !wasEdited}
                >
                  <Icon name="save" /> {__('Save class')}
                </Button>

                <Button
                  primary
                  type="button"
                  style={{
                    padding: '16px 24px',
                    display: groups.length > 0 && unsavedGroup === undefined && !wasEdited ? 'inline-block' : 'none',
                  }}
                  disabled={groups.length === 0 || unsavedGroup !== undefined || wasEdited}
                  onClick={this.nextStep}
                >
                  {__('Next step')} <Icon name="arrow right" />
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
    return (
      <Message negative>
        <Message.Header>{__('Display too small')}</Message.Header>
        <p>{__('Your display width is smaller than the minimum requested to use this feature. The minimum width is %s pixels.', minScreenWidth)}</p>
        <p>{__('If you are using a mobile device, like a smartphone or tablet, try using it on landscape orientation.')}</p>
      </Message>
    );
  }

  renderStartScreen = () => {
    const { screenWidth } = this.state;

    return (
      <Container>
        <Image src={utils.asset('/onboarding/Cadastre-suas-turmas.png')} size="large" centered style={{ marginBottom: '40px', marginTop: '42px' }} />
        <Header as="h2" style={{ marginBottom: '24px', marginTop: '0' }}>
          {__('Welcome to ClassApp!')}
        </Header>
        <p style={{ marginBottom: 0 }}>
          {__('Your ClassApp is almost ready!')}
        </p>
        <p>
          {__('Now, let`s create your classes and students.')}
        </p>
        {screenWidth >= minScreenWidth ?
          <Button
            primary
            type="button"
            style={{ padding: '16px 24px', marginTop: '18px' }}
            onClick={() => this.setState({ showForm: true })}
          >
            {__('Create your first class')} <Icon name="arrow right" />
          </Button>

          :

          <Message negative>
            <Message.Header>{__('Display too small')}</Message.Header>
            <p>{__('Your display width is smaller than the minimum requested to use this feature. The minimum width is %s pixels.', minScreenWidth)}</p>
            <p>{__('If you are using a mobile device, like a smartphone or tablet, try using it on landscape orientation.')}</p>
          </Message>
        }
      </Container>
    );
  }

  render() {
    const { showForm } = this.state;
    console.warn(this.state);
    return (
      <Container id="OnboardingGroups" style={{ paddingTop: '8px', maxHeight: '50%' }} textAlign="center" fluid>
        {showForm ? this.renderGroupForm() : this.renderStartScreen()}
      </Container>
    );
  }
}

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Button, Header, Icon, Popup, Image, Message, Table, Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import Modal from '../../components/Modal';
import Avatar from '../../components/Avatar';

const minScreenWidth = 450;

const groupIconStyle = {
  borderRadius: '1.25em',
  height: '2.5em',
  width: '2.5em',
  backgroundColor: 'rgb(222, 226, 230)',
  color: '#868e96',
  alignItems: 'center',
  display: 'inline-flex',
  flexDirection: 'column',
  padding: '0.55em',
};

@inject('store')
@graphql(gql`query OnboardingInvitesQuery($id: ID!) {
  node(id: $id) @connection(key: "Onboarding", filter: ["id"]) {
    ... on Onboarding {
      id: dbId
      step
      studentJson
      channelJson
      organization {
        id: dbId,
        isOnboarding
      }
    }
  }
}`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.onboarding_id
    }
  })
})
@graphql(gql`mutation deleteOnboarding($deleteOnboardingMutation: DeleteOnboardingInput!) {
  deleteOnboarding(input: $deleteOnboardingMutation) {
    clientMutationId
  }
}`, {
  name: 'deleteOnboarding',
  options: {
    refetchQueries: ['OnboardingInvitesQuery']
  }
})

@graphql(gql`mutation updateOrganization($updateOrganizationMutation: UpdateOrganizationInput!) {
  updateOrganization(input: $updateOrganizationMutation) {
    clientMutationId
  }
}`)
@observer
export default class OnboardingInvites extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Invites');
  }

  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      channels: [],
      groups: [],
      invitesSent: false,
      activeIndex: 0,
      show: {
        channels: [],
        groups: [],
      }
    };

    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.step) {
      const { step, studentJson, channelJson, organization } = nextProps.data.node;
      const { onboarding_id } = this.props.params;
      const groups = JSON.parse(studentJson);
      const channels = JSON.parse(channelJson);

      if (!organization.isOnboarding) {
        this.props.router.push('/');
      } else if (step === 'GROUPS' || groups.length === 0) {
        this.props.router.push(`/start/${onboarding_id}/groups`);
      } else if (channels.length === 0) {
        this.props.router.push(`/start/${onboarding_id}/channels`);
      } else {
        const show = {
          channels: Array(studentJson.length).fill(true),
          groups: Array(channelJson.length).fill(true),
        };
        this.setState({ step, groups, channels, show, organization });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ screenWidth: window.innerWidth });
  }

  sendInvites = (e) => {
    e.preventDefault();

    this.props.store.appends.push(
      <Modal
        key={'modal-send-invites'}
        onClose={() => this.props.store.appends.pop()}
        header={__('Do you confirm sending your invites?')}
        content={__('By confirming, your contacts will receive an invitation to join your institution in ClassApp.')}
        actions={[
          <Button content={<span><Icon name="arrow left" />{__('Back')}</span>} onClick={() => this.props.store.appends.pop()} floated="left" />,
          <Button
            primary content={__('Send invites')} onClick={() => {
              this.finishOnboarding(true);
              this.props.store.appends.pop();
            }}
          />
        ]}
      />
    );
  }

  finishOnboarding = (sendInvites = false) => {
    const { onboarding_id } = this.props.params;
    const { deleteOnboarding } = this.props;
    const { organization } = this.state;
    const request = { mutate: deleteOnboarding, input: 'deleteOnboardingMutation' };

    request.mutate({
      variables: {
        [request.input]: {
          id: onboarding_id,
          finishOnboarding: true,
        }
      }
    }).then(() => {
      if (sendInvites) {
        this.props.mutate({
          variables: {
            updateOrganizationMutation: {
              id: organization.id,
              //link: this.props.store.currentOrganization.link && this.props.store.currentOrganization.link.search('://') === -1 ?
              //  'http://' + this.props.store.currentOrganization.link : this.props.store.currentOrganization.link,
              invite: this.props.location.query.type || 'LIVE'
            }
          }
        }).then(() => {
          this.setState({ invitesSent: true });
        }).catch((err) => {
          console.error(err);
          this.props.store.appends.push(
            <Modal
              key={'modal-error-send-invites'}
              onClose={() => {
                this.props.router.push(`/start/${onboarding_id}/tips`);
                this.props.store.appends.pop();
              }}
              header={__('Error')}
              content={__('Error sending the invites, try again later.')}
              actions={[
                <Button
                  content={<span><Icon name="times" />{__('Close')}</span>}
                  onClick={() => {
                    this.props.router.push(`/start/${onboarding_id}/tips`);
                    this.props.store.appends.pop();
                  }}
                />
              ]}
            />
          );
        });
      } else {
        this.props.router.push(`/start/${onboarding_id}/tips`);
      }
    }).catch((err) => {
      this.props.store.appends.push(
        <Modal
          key={'modal-error-delete-onboarding'}
          onClose={() => this.props.store.appends.pop()}
          header={__('Error')}
          content={__('Error on create users and groups.')}
          actions={[
            <Button
              content={<span><Icon name="times" />{__('Close')}</span>}
              onClick={() => {
                this.props.store.appends.pop();
              }}
            />
          ]}
        />
      );
      console.error(err);
    });
  }

  handleItemClick = (e, i, type = 'groups') => {
    const { show } = this.state;

    show[type][i] = !show[type][i];

    this.setState({ show });
  }

  confirmNoInvites = () => {
    this.props.store.appends.push(
      <Modal
        key={'modal-leave'}
        onClose={() => this.props.store.appends.pop()}
        header={__('Do you really want to send your invites later?')}
        content={__('Are you sure you want to send invitations later? You can do this at any time.')}
        actions={[
          <Button content={<span><Icon name="arrow left" />{__('Back')}</span>} onClick={() => this.props.store.appends.pop()} floated="left" />,
          <Button id="SendLaterButton" primary content={__('Send later')} onClick={() => { this.finishOnboarding(false); this.props.store.appends.pop(); }} />,
        ]}
      />
    );
  }

  renderConfirmInfo = () => {
    const { groups, channels } = this.state;
    const { onboarding_id } = this.props.params;

    return (
      <Container>
        <Header as="h2" style={{ marginBottom: '24px', marginTop: '40px' }}>
          {__('Send invites')}
        </Header>
        <p style={{ marginBottom: '0' }}>
          {__('To start using ClassApp, we will send invitations to the people listed below.')}
        </p>
        <p style={{ margin: '0 auto' }}>
          <style>{'#seeExample { box-shadow: none !important; }'}</style>
          <Button
            primary
            basic
            type="button"
            style={{ padding: '3px 24px', marginTop: '0' }}
            onClick={() => {
              this.props.store.appends.push(
                <Modal
                  key={'modal-example'}
                  onClose={() => this.props.store.appends.pop()}
                  header={__('Invite example')}
                  content={<Image src={utils.asset('/onboarding/Exemplo-convite.png')} />}
                  actions={[
                    <Button content={__('Close')} onClick={() => this.props.store.appends.pop()} />,
                  ]}
                />
              );
            }}
            id="seeExample"
          >
            {__('See an invitation example')}
          </Button>
        </p>
        <style>{'#changeGroups, #changeChannels { box-shadow: none !important; }'}</style>
        <div style={{ marginTop: '2em', textAlign: 'left' }}>
          <div style={{ display: 'inline-block' }}>
            <Header as="h3" style={{ marginBottom: '1em' }}>
              {`${__('Channels')} (${channels.length})`}
            </Header>
          </div>
          <div style={{ display: 'inline-block' }}>
            <Button basic color="blue" onClick={() => this.props.router.push(`/start/${onboarding_id}/channels`)} id="changeChannels" >
              {__('Change')}
            </Button>
          </div>
          {this.renderItemsBlock('channels', channels)}
        </div>
        <div style={{ marginTop: '3em', textAlign: 'left' }} >
          <div style={{ display: 'inline-block' }}>
            <Header as="h3" style={{ marginBottom: '1em' }}>
              {`${__('Groups')} (${groups.length})`}
            </Header>
          </div>
          <div style={{ display: 'inline-block' }}>
            <Button basic color="blue" onClick={() => this.props.router.push(`/start/${onboarding_id}/groups`)} id="changeGroups" >
              {__('Change')}
            </Button>
          </div>
          {this.renderItemsBlock('groups', groups)}
        </div>
        <div style={{ marginTop: '3em' }} >
          <Button type="button" style={{ padding: '16px 24px', float: 'left' }} onClick={this.confirmNoInvites}>
            {__('Save, but do not send invites')}
          </Button>
          <Button
            primary
            type="button"
            style={{ padding: '16px 24px', float: 'right' }}
            disabled={groups.length === 0}
            onClick={this.sendInvites}
          >
            {__('Send invites')} <Icon name="arrow right" />
          </Button>
        </div>
      </Container>
    );
  }

  renderItemsBlock = (type = 'groups', items = []) => {
    const { show } = this.state;

    const rows = items.map((item, index) => {
      const members = type === 'channels' ? item.people : item.students;

      const membersInfo = members.map((m, i) => {
        if (type === 'channels') {
          return (
            <Table.Row>
              <Table.Cell style={i !== members.length - 1 ? { boxShadow: `0px 1px rgba(0,0,0,${index === 0 ? 0.1 : 0.01})` } : {}}>
                <div style={{ alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                  <Avatar avatar alt={m.name} />
                  {m.name.length > 30 ?
                    <Popup
                      trigger={<div style={{ margin: 'auto 4px' }}>{m.name.replace(/^(.{30}).+/, '$1...')}</div>}
                      content={<span>{m.name}</span>}
                    />
                    :
                    <div style={{ margin: 'auto 4px' }}>{m.name}</div>}
                </div>
              </Table.Cell>
              <Table.Cell style={i !== members.length - 1 ? { boxShadow: `0px 1px rgba(0,0,0,${index === 0 ? 0.1 : 0.01})` } : {}}>
                {m.phone.phoneNumber === '' ? '-' : `${m.phone.countryCode} ${m.phone.phoneNumber}`}
              </Table.Cell>
              <Table.Cell style={i !== members.length - 1 ? { boxShadow: `0px 1px rgba(0,0,0,${index === 0 ? 0.1 : 0.01})` } : {}}>
                {m.email === '' ? '-' : m.email}
              </Table.Cell>
            </Table.Row>
          );
        }
        const extraContact = [];
        let email = '-';
        let phone = '-';

        if (m.phone.phoneNumber === '') {
          if (m.extraPhone.phoneNumber !== '') {
            phone = `${m.extraPhone.countryCode} ${m.extraPhone.phoneNumber}`;
          }
        } else {
          phone = `${m.phone.countryCode} ${m.phone.phoneNumber}`;
          if (m.extraPhone.phoneNumber !== '') {
            extraContact.push(`${m.extraPhone.countryCode} ${m.extraPhone.phoneNumber}`);
          }
        }

        if (m.email === '') {
          if (m.extraEmail !== '') {
            email = m.extraEmail;
          }
        } else {
          email = m.email;
          if (m.extraEmail !== '') {
            extraContact.push(m.extraEmail);
          }
        }

        return (
          <Table.Row>
            <Table.Cell style={i !== members.length - 1 ? { boxShadow: `0px 1px rgba(0,0,0,${index === 0 ? 0.1 : 0.01})` } : {}}>
              <div style={{ alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                <Avatar avatar alt={m.name} />
                {m.name.length > 30 ?
                  <Popup
                    trigger={<div style={{ margin: 'auto 4px' }}>{m.name.replace(/^(.{30}).+/, '$1...')}</div>}
                    content={<span>{m.name}</span>}
                  />
                  :
                  <div style={{ margin: 'auto 4px' }}>{m.name}</div>}
              </div>
            </Table.Cell>
            <Table.Cell style={i !== members.length - 1 ? { boxShadow: `0px 1px rgba(0,0,0,${index === 0 ? 0.1 : 0.01})` } : {}}>
              {phone}
            </Table.Cell>
            <Table.Cell style={i !== members.length - 1 ? { boxShadow: `0px 1px rgba(0,0,0,${index === 0 ? 0.1 : 0.01})` } : {}}>
              {email}
            </Table.Cell>
            <Table.Cell style={i !== members.length - 1 ? { boxShadow: `0px 1px rgba(0,0,0,${index === 0 ? 0.1 : 0.01})` } : {}}>
              <Popup
                trigger={<Button fluid basic content={extraContact.length > 0 ? `+${extraContact.length}` : '-'} disabled={extraContact.length === 0} />}
              >
                {extraContact.map(c => <p>{c}</p>)}
              </Popup>

            </Table.Cell>
          </Table.Row>
        );
      });

      return (
        <Table.Row>
          <Table.Cell>
            <Table style={{ border: '0' }}>
              <Table.Body>
                <Table.Row style={index === 0 && !!show[type][index] ? { boxShadow: `0px 1px rgba(0,0,0,${0.1})` } : {}}>
                  <Table.Cell width={6}>
                    <Icon name="users" style={groupIconStyle} />
                    <span style={{ marginLeft: '0.7em' }}>{item.name}</span>
                  </Table.Cell>
                  <Table.Cell width={9}>
                    <span>
                      {type === 'channels' ?
                        `${members.length} ${members.length === 1 ? __('person') : __('people')}` :
                        `${members.length} ${members.length === 1 ? __('student') : __('students')}`}
                    </span>
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Button icon={`arrow ${show[type][index] ? 'up' : 'down'}`} onClick={e => this.handleItemClick(e, index, type)} fluid />
                  </Table.Cell>
                </Table.Row>
                {show[type][index] ?
                  <Table.Row>
                    <Table.Cell colSpan="3">
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={6}>{type === 'channels' ? __('Member name') : __('Student name')}</Table.HeaderCell>
                            <Table.HeaderCell width={4}>{__('Phone')}</Table.HeaderCell>
                            <Table.HeaderCell width={type === 'channels' ? 6 : 5}>{__('E-mail')}</Table.HeaderCell>
                            {type === 'channels' ? null : <Table.HeaderCell width={1} />}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {membersInfo}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  </Table.Row>
                  : null}
              </Table.Body>
            </Table>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Container textAlign="left">
        <Table stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8}>{type === 'channels' ? __('Channel name') : __('Group name')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rows}
          </Table.Body>
        </Table>

      </Container>

    );
  }

  renderPostScreen = () => {
    const { screenWidth } = this.state;
    const { onboarding_id } = this.props.params;

    return (
      <Container>
        <Image src={utils.asset('/onboarding/Convites-enviados.png')} size="medium" centered style={{ marginBottom: '40px', marginTop: '42px' }} />
        <Header as="h2" style={{ marginBottom: '24px', marginTop: '0' }}>
          {__('The invitations were sent')}
        </Header>
        <p style={{ margin: '0 auto', maxWidth: '600px', textAlign: 'center' }}>
          {__('Congratulations! Now your institution and families will be even closer.')}
        </p>
        <p style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '600px', textAlign: 'center' }}>
          {__('The registered parents and employees will receive invitations and instructions to use ClassApp.')}
        </p>
        <p style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '600px', textAlign: 'center' }}>
          {__('Now that you have submitted the invitations, it is very important to publicize the ClassApp to ensure good engagement from everyone.')}
        </p>

        {screenWidth >= minScreenWidth ?
          <Button
            primary
            type="button"
            style={{ padding: '16px 24px', marginTop: '18px' }}
            onClick={() => this.props.router.push(`/start/${onboarding_id}/tips`)}
          >
            {__('Start divulgation')} <Icon name="arrow right" />
          </Button>

          :

          <Message negative>
            <Message.Header>{__('Display too small')}</Message.Header>
            <p>{__('Your display width is smaller than the minimum requested to use this feature. The minimum width is %s pixels.', minScreenWidth)}</p>
            <p>{__('If you are using a mobile device, like a smartphone or tablet, try using it on landscape orientation.')}</p>
          </Message>
        }
      </Container>
    );
  }

  render() {
    const { invitesSent, selectedChannel, channels, unsavedChannel, step } = this.state;

    if (step) {
      return (
        <Container id="OnboardingInvites" style={{ paddingTop: '8px', maxHeight: '50%' }} textAlign="center">
          {!invitesSent ? this.renderConfirmInfo() : this.renderPostScreen()}
        </Container>
      );
    }
    return (
      <Container id="OnboardingInvites" style={{ paddingTop: '8px', maxHeight: '50%' }} textAlign="center">
        <Loader active />
      </Container>
    );
  }
}
